import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { userStore } from "@/store/userStore";

export default async function (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    let { user, authenticate } = userStore();
    if (to.meta.hasOwnProperty('isLoggedIn')) {
        if (!user.isLoggedIn) {
            await authenticate().then((d: any) => {
                // next(true);
                // @ts-ignore
                if (to.meta.isRole.includes(d.roleId)) {
                    next(true);
                } else if (d.roleId === 1) {
                    next('/location');
                } else if (d.roleId === 2) {
                    next('/location');
                } else if (d.roleId === 3) {
                    next('/employees');
                } else if (d.roleId === 4) {
                    next('/tools');
                } else if (d.roleId === 5) {
                    next('/materials');
                } else if (d.roleId === 6) {
                    next('/time_sheet');
                } else if (d.roleId === 7) {
                    next('/location');
                } else if (d.roleId === 8) {
                    next('/location');
                } else if (d.roleId === 9) {
                    next('/location');
                } else if (d.roleId === 10) {
                    next('/location');
                } else if (d.roleId === 11) {
                    next('/location');
                } else if (d.roleId === 12) {
                    next('/requests_payments');
                } else if (d.roleId === 13) {
                    next('/location');
                } else {
                    next('/login');
                }
            }).catch((e) => {
                next('/login');
            });
        } else {
            next(true);
        }
    } else {
        next(true);
    }
}
