import { RouteRecordRaw } from "vue-router";

export const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Index',
        redirect: '/location',
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/AuthView.vue'),
    },
    {
        path: '/location',
        name: 'LocationIndex',
        component: () => import(/* webpackChunkName: "location" */ '../views/location/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 2, 3,4,5,6,7,8,9,10,11,12, 13 ],
        },
    },
    {
        path: '/location/:locomotiveId/:sectionId/:date?',
        name: 'LocationShow',
        component: () => import(/* webpackChunkName: "location" */ '@/views/location/ShowView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 2, 3,4,5,6,7,8,9,10,11,12, 13 ],
        },
    },
    {
        path: '/report',
        name: 'ReportIndex',
        component: () => import(/* webpackChunkName: "report" */ '../views/report/ReportView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 3, 4, 5, 2, 9, 13 ],
        },
    },
    {
        path: '/report/tools',
        name: 'ReportToolIndex',
        component: () => import(/* webpackChunkName: "report-tool" */ '../views/report/ToolView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 3, 4, 5, 2, 9 ],
        },
    },
    {
        path: '/report/materials',
        name: 'ReportMaterialIndex',
        component: () => import(/* webpackChunkName: "report-material" */ '../views/report/MaterialView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 3, 4, 5, 2, 9 ],
        },
    },
    {
        path: '/report/print',
        name: 'ReportPrintIndex',
        component: () => import(/* webpackChunkName: "report-print" */ '../views/report/PrintView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 3, 4, 5, 2, 9 ],
        },
    },
    {
        path: '/employees',
        name: 'EmployeeIndex',
        component: () => import(/* webpackChunkName: "employees" */ '../views/employees/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 3, 13, 12 ],
        },
    },
    {
        path: '/employee-report-card',
        name: 'EmployeeReportCardViewIndex',
        component: () => import(/* webpackChunkName: "employees" */ '../views/employees/ReportCardView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 3, 13, 12 ],
        },
    },
    {
        path: '/locomotives',
        name: 'LocomotiveIndex',
        component: () => import(/* webpackChunkName: "locomotives" */ '../views/locomotives/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 4, 5, 2, 9, 10 ],
        },
    },
    {
        path: '/tools_employees',
        name: 'ToolsEmployeesIndex',
        component: () => import(/* webpackChunkName: "toolsemployees" */ '../views/toolsemployees/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 4 ],
        },
    },
    {
        path: '/tools_employees/receipt',
        name: 'ToolsEmployeesReceiptIndex',
        component: () => import(/* webpackChunkName: "toolsemployees-receipt" */ '../views/toolsemployees/ReceiptView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 4, 5, 2, 10 ],
        },
    },
    {
        path: '/tools_employees/release',
        name: 'ToolsEmployeesReleaseIndex',
        component: () => import(/* webpackChunkName: "toolsemployees-release" */ '../views/toolsemployees/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 4, 5, 2, 10 ],
        },
    },
    {
        path: '/materials',
        name: 'MaterialsIndex',
        component: () => import(/* webpackChunkName: "materials" */ '../views/materials/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 4, 5, 2, 9, 10, 12 ],
        },
    },
    {
        path: '/materials/:id',
        name: 'MaterialsShow',
        component: () => import(/* webpackChunkName: "materials-show" */ '../views/materials/ShowView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 4, 5, 2, 9, 10 ],
        },
    },
    // {
    //     path: '/stock',
    //     name: 'StockIndex',
    //     component: () => import(/* webpackChunkName: "stock" */ '../views/stock/IndexView.vue'),
    //     meta: {
    //         isLoggedIn: true,
    //         isRole: [ 1, 5 ],
    //     },
    // },
    {
        path: '/stock/inventory_residues',
        name: 'InventoryResiduesIndex',
        component: () => import(/* webpackChunkName: "inventory-residues-index" */ '../views/stock/InventoryResiduesView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 4, 5, 2, 10, 12, 13 ],
        },
    },
    {
        path: '/stock/receipt',
        name: 'StockReceiptIndex',
        component: () => import(/* webpackChunkName: "stock-receipt" */ '../views/stock/ReceiptView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 4, 5, 2, 10, 12, 13 ],
        },
    },
    {
        path: '/stock/release',
        name: 'StockReleaseIndex',
        component: () => import(/* webpackChunkName: "stock-release" */ '../views/stock/ReleaseView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 4, 5, 2, 10, 12, 13 ],
        },
    },
    {
        path: '/materials-to-be-received',
        name: 'MaterialsToBeReceivedIndex',
        component: () => import(/* webpackChunkName: "materials-to-be-received" */ '../views/materialsToBeReceived/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 4, 5, 2, 10, 12, 13 ],
        },
    },
    {
        path: '/tools',
        name: 'ToolIndex',
        component: () => import(/* webpackChunkName: "tools" */ '../views/tools/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 4, 5, 2, 9, 10, 13 ],
        },
    },
    {
        path: '/mat_norms',
        name: 'MatNormsIndex',
        component: () => import(/* webpackChunkName: "mat-norms" */ '../views/matNorms/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 4, 5, 2, 9 ],
        },
    },
    {
        path: '/process_engineer/form_one',
        name: 'ProcessEngineerFormOneIndex',
        component: () => import(/* webpackChunkName: "process-engineer-form-one" */ '../views/processEngineer/FormOneView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/process_engineer/form_two',
        name: 'ProcessEngineerFormTwoIndex',
        component: () => import(/* webpackChunkName: "process-engineer-form-two" */ '../views/processEngineer/FormTwoView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 2 ],
        },
    },
    {
        path: '/purchase_requests',
        name: 'PurchaseRequestsIndex',
        component: () => import(/* webpackChunkName: "purchase-requests" */ '../views/purchaseRequests/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 8, 5, 2, 10, 13, 12 ],
        },
    },
    {
        path: '/oauth2callback',
        redirect: (to) => {
            const _redirect = localStorage.getItem('google_redirect');
            localStorage.removeItem('google_redirect');
            const redirect = _redirect === 'formOne' ? '/process_engineer/form_one' : _redirect === 'formTwo' ? '/process_engineer/form_two' : '';
            return { path: redirect, query: { code: to.query.code } };
        },
    },
    {
        path: '/power_calculation',
        name: 'PowerCalculationIndex',
        component: () => import(/* webpackChunkName: "power-calculation-index" */ '../views/powerCalculation/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 5, 2, 9 ],
        },
    },
    {
        path: '/maintenance_standards',
        name: 'MaintenanceStandardsIndex',
        component: () => import(/* webpackChunkName: "maintenance-standards-index" */ '../views/maintenanceStandards/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/material_requirement_new',
        name: 'MaterialRequirementNewIndex',
        component: () => import(/* webpackChunkName: "material-requirement-new-index" */ '../views/materialRequirementNew/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 8, 5, 2, 10, 13 ],
        },
    },
    {
        path: '/material_requirement_new/:id',
        name: 'MaterialRequirementNewShow',
        component: () => import(/* webpackChunkName: "material-requirement-new-show" */ '../views/materialRequirementNew/ShowView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 8, 5, 2, 10, 13 ],
        },
    },
    {
        path: '/material_requirement',
        name: 'MaterialRequirementIndex',
        component: () => import(/* webpackChunkName: "material-requirement-index" */ '../views/materialRequirement/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 8, 5, 2, 10 ],
        },
    },
    {
        path: '/time_sheet',
        name: 'TimeSheetIndex',
        component: () => import(/* webpackChunkName: "time-sheet-index" */ '../views/timeSheet/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 3 ],
        },
    },
    {
        path: '/loading_ditches',
        name: 'LoadingDitchesIndex',
        component: () => import(/* webpackChunkName: "loading-ditches-index" */ '../views/loadingDitches/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 5, 2, 9, 10, 13 ],
        },
    },
    {
        path: '/time_sheet/print',
        name: 'TimeSheetPrint',
        component: () => import(/* webpackChunkName: "time-sheet-print" */ '../views/timeSheet/PrintView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 6, 3 ],
        },
    },
    {
        path: '/list_equipment',
        name: 'ListEquipmentIndex',
        component: () => import(/* webpackChunkName: "list-equipment-index" */ '../views/listEquipment/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 4, 5, 2, 9, 13 ],
        },
    },
    {
        path: '/supplier',
        name: 'SupplierIndex',
        component: () => import(/* webpackChunkName: "supplier-index" */ '../views/supplier/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 4, 5, 2, 9, 10, 12 ],
        },
    },
    {
        path: '/invoices',
        name: 'InvoiceIndex',
        component: () => import(/* webpackChunkName: "invoice-index" */ '../views/invoices/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 4, 5, 2, 10, 12, 13 ],
        },
    },
    {
        path: '/invoices/:id',
        name: 'InvoiceShow',
        component: () => import(/* webpackChunkName: "invoice-show" */ '../views/invoices/ShowView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 4, 5, 2, 10, 12 ],
        },
    },
    {
        path: '/supplier_selection',
        name: 'SupplierSelectionIndex',
        component: () => import(/* webpackChunkName: "supplier-selection-index" */ '../views/supplierSelection/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 10, 11, 13, 12 ],
        },
    },
    {
        path: '/supplier_selection/:id',
        name: 'SupplierSelectionShow',
        component: () => import(/* webpackChunkName: "supplier-selection-show" */ '../views/supplierSelection/ShowView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 6, 3, 7, 13 ],
        },
    },
    {
        path: '/requests_payments',
        name: 'RequestsPaymentsIndex',
        component: () => import(/* webpackChunkName: "requests-payments-index" */ '../views/requestsPayments/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 12 ],
        },
    },
    {
        path: '/payment_registers',
        name: 'PaymentRegistersIndex',
        component: () => import(/* webpackChunkName: "payment-registers-index" */ '../views/paymentRegisters/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 12 ],
        },
    },
    {
        path: '/operational_accounting',
        name: 'OperationalAccountingView',
        component: () => import(/* webpackChunkName: "operational-accounting" */ '@/views/operationalAccounting/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 6, 13, 2 ],
        },
    },
    {
        path: '/section_job_summary',
        name: 'SectionJobSummaryIndex',
        component: () => import(/* webpackChunkName: "section-job-summary-index" */ '@/views/sectionJobSummary/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 6, 13, 2 ],
        },
    },
    {
        path: '/employee_tariff',
        name: 'EmployeeTariffIndex',
        component: () => import(/* webpackChunkName: "employee-tariff-index" */ '@/views/employeeTariff/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 6 ],
        },
    },
    {
        path: '/prices',
        name: 'PricesView',
        component: () => import(/* webpackChunkName: "await" */ '../views/AwaitView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 6 ],
        },
    },
    {
        path: '/users',
        name: 'UserIndex',
        component: () => import('@/views/user/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [7],
        },
    },
    {
        path: '/departments',
        name: 'DepartmentIndex',
        component: () => import('@/views/department/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [7],
        },
    },
    {
        path: '/booking-materials-list',
        name: 'BookingMaterialsListIndex',
        component: () => import('@/views/bookingMaterials/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1, 7, 8, 5, 2, 10, 13, 12 ],
        },
    },
    {
        path: '/project-register',
        name: 'ProjectRegisterIndex',
        component: () => import('@/views/projectRegister/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [1, 7, 8, 9, 11, 12],
        },
    },
    {
        path: '/project-schedule',
        name: 'ProjectScheduleIndex',
        component: () => import('@/views/ProjectSchedule/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [1, 7, 8, 9, 11, 12],
        },
    },
    {
        path: '/completed-projects',
        name: 'CompletedProjectsIndex',
        component: () => import('@/views/CompletedProjects/IndexView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [1, 7, 8, 9, 11, 12],
        },
    },
    {
        path: '/documents',
        name: 'DocumentsIndex',
        component: () => import('@/views/document/DocumentView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [1, 2, 7, 8, 9, 12],
        },
    },
    {
        path: '/empty',
        name: 'Empty',
        component: () => import(/* webpackChunkName: "empty" */ '../views/EmptyView.vue'),
    },
    {
        path: '/await1',
        name: 'Await1',
        component: () => import(/* webpackChunkName: "await" */ '../views/AwaitView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/await111',
        name: 'Await111',
        component: () => import(/* webpackChunkName: "await" */ '../views/AwaitView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/await112',
        name: 'Await112',
        component: () => import(/* webpackChunkName: "await" */ '../views/AwaitView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/await113',
        name: 'Await113',
        component: () => import(/* webpackChunkName: "await" */ '../views/AwaitView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/await114',
        name: 'Await114',
        component: () => import(/* webpackChunkName: "await" */ '../views/AwaitView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/await2',
        name: 'Await2',
        component: () => import(/* webpackChunkName: "await" */ '../views/AwaitView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/await3',
        name: 'Await3',
        component: () => import(/* webpackChunkName: "await" */ '../views/AwaitView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/await4',
        name: 'Await4',
        component: () => import(/* webpackChunkName: "await" */ '../views/AwaitView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/Await13222',
        name: 'Await13222',
        component: () => import(/* webpackChunkName: "await" */ '../views/AwaitView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/await5',
        name: 'Await5',
        component: () => import(/* webpackChunkName: "await" */ '../views/AwaitView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/await6',
        name: 'Await6',
        component: () => import(/* webpackChunkName: "await" */ '../views/AwaitView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/await7',
        name: 'Await7',
        component: () => import(/* webpackChunkName: "await" */ '../views/AwaitView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/await10',
        name: 'Await10',
        component: () => import(/* webpackChunkName: "await" */ '../views/AwaitView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/await11',
        name: 'Await11',
        component: () => import(/* webpackChunkName: "await" */ '../views/AwaitView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/await12',
        name: 'Await12',
        component: () => import(/* webpackChunkName: "await" */ '../views/AwaitView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/await13',
        name: 'Await13',
        component: () => import(/* webpackChunkName: "await" */ '../views/AwaitView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/await14',
        name: 'Await14',
        component: () => import(/* webpackChunkName: "await" */ '../views/AwaitView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/await15',
        name: 'Await15',
        component: () => import(/* webpackChunkName: "await" */ '../views/AwaitView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/await16',
        name: 'Await16',
        component: () => import(/* webpackChunkName: "await" */ '../views/AwaitView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/await13',
        name: 'Await13',
        component: () => import(/* webpackChunkName: "await" */ '../views/AwaitView.vue'),
        meta: {
            isLoggedIn: true,
            isRole: [ 1 ],
        },
    },
    {
        path: '/403',
        name: 'Error403',
        component: () => import(/* webpackChunkName: "error-403" */ '../views/Page403View.vue'),
    },
    {
        path: '/500',
        name: 'Error500',
        component: () => import(/* webpackChunkName: "error-500" */ '../views/Page500View.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import(/* webpackChunkName: "page-404" */ '../views/Page404View.vue'),
        meta: { public: true },
    },
];
