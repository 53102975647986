import { computed, reactive, readonly, ComputedRef } from "vue";
import axios, { AxiosPromise } from "axios";
import api from '@/api';
import { log } from "@/utils/log";
import { IUnknownObject } from '@/@types/common';

// Временные константы ролей

export interface IUserState {
    id: number,
    roleId: number,
    name: string,
    login: string,
    depoName: string,
    depots: any[],
    isLoggedIn: ComputedRef,
    isAdmin: ComputedRef,
    isTechnologist: ComputedRef,
    isHRD: ComputedRef,
    isToolmaker: ComputedRef,
    isSklad: ComputedRef,
    isMaster: ComputedRef,
    isSuperAdmin: ComputedRef,
    isCEO: ComputedRef,
    isKadry: ComputedRef,
    isInstr: ComputedRef,
    isCentrsklad: ComputedRef,
    isSales: ComputedRef,
    isZakup: ComputedRef,
    isCTO: ComputedRef,
    isAccountant: ComputedRef,
    isHeadOfDepot: ComputedRef,
}

const state: IUserState = reactive(<IUserState>{
    id: 0,
    roleId: 0,
    name: '',
    login: '',
    depoName: '',
    depots: [],
    isLoggedIn: computed((): boolean => state.id !== 0), // ++
    isAdmin: computed((): boolean => state.roleId === 1), // ++
    isTechnologist: computed((): boolean => state.roleId === 2), // ++
    isHRD: computed((): boolean => state.roleId === 3),
    isKadry: computed((): boolean => state.roleId === 3), // ++
    isToolmaker: computed((): boolean => state.roleId === 4),
    isInstr: computed((): boolean => state.roleId === 4), // ++
    isSklad: computed((): boolean => state.roleId === 5),
    isCentrsklad: computed((): boolean => state.roleId === 5), // ++
    isMaster: computed((): boolean => state.roleId === 6),
    isSuperAdmin: computed((): boolean => state.roleId === 7),
    isCEO: computed((): boolean => state.roleId === 8),
    isSales: computed((): boolean => state.roleId === 9),
    isZakup: computed((): boolean => state.roleId === 10),
    isCTO: computed((): boolean => state.roleId === 11),
    isAccountant: computed((): boolean => state.roleId === 12),
    isHeadOfDepot: computed(() => state.roleId === 13),
});

function updateStore(args: IUnknownObject) {
    if (args.hasOwnProperty('id')) state.id = args.id;
    if (args.hasOwnProperty('roleId')) state.roleId = args.roleId;
    if (args.hasOwnProperty('name')) state.name = args.login;
    if (args.hasOwnProperty('name')) state.login = args.login;
    if (args.hasOwnProperty('depoName')) state.depoName = args.depoName;
    if (args.hasOwnProperty('depots')) state.depots = args.depots;
}

function login(login: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
        return api.user.auth({ login: login, password: password }).then(r => {
            console.log(r);
            localStorage.setItem('token', r.data.token);
            updateStore({ id: 0 });
            resolve(r.data);
        }).catch(e => {
            reject(e.data);
        });
    });
}

function logout(): Promise<boolean> {
    return new Promise((resolve, reject) => {
        localStorage.removeItem('token');
        updateStore({ id: 0 });
        resolve(true);
    });
}

function authenticate(): AxiosPromise {
    log('userStore:authenticate');
    return new Promise((resolve, reject) => {
        return api.user.check({
            token: localStorage.getItem('token'),
        }).then((r: any) => {
            updateStore(r.data);
            let getDepoId = localStorage.getItem('depoId');
            if (getDepoId == null || r.data.depots.findIndex((i: {depoId: number, depoName: string }) => i.depoId === parseInt(getDepoId as string)) === -1) {
                localStorage.setItem('depoId', r.data.depots[0].depoId);
            }
            resolve(r.data);
        }).catch((e: any) => {
            reject(e.data);
        });
    });
}

export function userStore() {
    return {
        user: readonly(<IUserState>state),
        logout,
        login,
        authenticate,
    };
}
