import { ref } from "vue";
import { isMobile } from "@/composables/useBreakpoints";

const DEFAULT = 'default';
const COMPACT = 'compact';

const sidebar = ref(localStorage.getItem('sidebar') || DEFAULT);
if (!isMobile) {
    document.body.setAttribute('data-sidebar-layout', sidebar.value);
}

function setDefaultSidebar() {
    sidebar.value = DEFAULT;
    localStorage.setItem('sidebar', DEFAULT);
    document.body.setAttribute('data-sidebar-layout', sidebar.value);
}

function setCompactSidebar() {
    sidebar.value = COMPACT;
    localStorage.setItem('sidebar', COMPACT);
    document.body.setAttribute('data-sidebar-layout', sidebar.value);
}

export function appStore() {
    return {
        sidebar,
        setCompactSidebar,
        setDefaultSidebar,
    };
}
