<template>
    <router-view />
    <modal-wrapper modal-name="confirm_modal" />
    <modal-wrapper modal-name="locomotive_modal" />
    <modal-wrapper modal-name="employee_modal" />
    <modal-wrapper modal-name="tool_modal" />
    <modal-wrapper modal-name="move_locomotive_modal" />
    <modal-wrapper modal-name="choose_date_modal" />
    <modal-wrapper modal-name="text_modal" />
    <modal-wrapper modal-name="tool_employee_modal" />
    <modal-wrapper modal-name="grid_modal" />
    <modal-wrapper modal-name="material_modal" />
    <modal-wrapper modal-name="merge_material_modal" />
    <modal-wrapper modal-name="stock_modal" />
    <modal-wrapper modal-name="process_engineer_form_two_modal" />
    <modal-wrapper modal-name="power_calculation_edit_modal" />
    <modal-wrapper modal-name="maintenance_standard_modal" />
    <modal-wrapper modal-name="maintenance_exceptions_modal" />
    <modal-wrapper modal-name="tool_employee_receipt_modal" />
    <modal-wrapper modal-name="supplier_modal" />
    <modal-wrapper modal-name="price_modal" />
    <modal-wrapper modal-name="add_section_modal" />
    <modal-wrapper modal-name="loading_ditches_list_modal" />
    <modal-wrapper modal-name="item_equipment_modal" />
    <modal-wrapper modal-name="material_requirement_modal" />
    <modal-wrapper modal-name="new_user_modal" />
    <modal-wrapper modal-name="edit_user_modal" />
    <modal-wrapper modal-name="change_password_modal" />
    <modal-wrapper modal-name="add_material_material_requirement_modal" />
    <modal-wrapper modal-name="invoice_modal" />
    <modal-wrapper modal-name="invoice_material_modal" />
    <modal-wrapper modal-name="add_invoice_material_modal" />
    <modal-wrapper modal-name="send_payment_order_modal" />
    <modal-wrapper modal-name="section_job_summary_modal" />
    <modal-wrapper modal-name="supplier_selection_modal" />
    <modal-wrapper modal-name="supplier_selection_winner_modal" />
    <modal-wrapper modal-name="supplier_selection_attachment_modal" />
    <modal-wrapper modal-name="employee_tariff_modal" />
    <modal-wrapper modal-name="department_modal" />
    <modal-wrapper modal-name="close_purchase_request_from_stock_modal" />
    <modal-wrapper modal-name="section_job_contract_number_modal" />
    <modal-wrapper modal-name="invoice_from_to_be_received_modal" />
    <modal-wrapper modal-name="select_locomotive_in_material_requirement_modal" />
    <modal-wrapper modal-name="project_register_modal" />
    <modal-wrapper modal-name="project_register_price_modal" />
    <modal-wrapper modal-name="project_register_datasheet_modal" />
    <modal-wrapper modal-name="document_modal" />
    <modal-wrapper modal-name="edit_document_modal" />
    <modal-wrapper modal-name="edit_required_material_modal" />
    <modal-wrapper modal-name="employee_order_modal" />
    <Notifications position="bottom right" />
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent, onMounted, ref } from "vue";
import moment from 'moment-timezone';
import ModalWrapper from "@/components/common/ModalWrapper.vue";
import { Notifications } from "@kyvg/vue3-notification";

export default defineComponent({
    name: "App",
    setup() {
        onMounted(() => {

        });

        moment.tz.setDefault("Asia/Almaty");

        return {

        };
    },
    components: {
	    ModalWrapper,
    },
});
</script>

<style lang="scss">

</style>
